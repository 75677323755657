const breadcrumb = [
  { path: "match-manager", name: "Quản lý trận đấu" },
  { path: "player-manager", name: "Quản lý người chơi" },
  { path: "message-manager", name: "Quản lý gửi tin nhắn" },
  { path: "match-result-manager", name: "Quản lý vòng đấu" },
  { path: "gift-manager", name: "Quản lý quà" },
];

const updatedScore = {
  status: 3,
  label: "Đã cập nhật tỷ số",
  name: "Đã cập nhật tỷ số",
  color: "#52c41a",
};
const confirmed = {
  status: 4,
  label: "Đã chốt danh sách",
  name: "Đã chốt danh sách",
  color: "#faad14",
};
const canceled = {
  status: -4,
  label: "Đã hủy danh sách",
  name: "Đã hủy danh sách",
  color: "#bfbfbf",
};

const statusMatch = [
  {
    status: 1,
    label: "Khởi tạo",
    name: "Khởi tạo",
    color: "#08979c",
  },
  {
    status: 2,
    label: "Chưa cập nhật tỷ số",
    name: "Chưa cập nhật tỷ số",
    color: "#1890ff",
  },
  {
    ...updatedScore,
  },
  {
    ...confirmed,
  },
  {
    ...canceled,
  },
];

const notGuess = {
  status: 1,
  name: "Chưa mở dự đoán",
  label: "Chưa mở dự đoán",
  color: "#bfbfbf",
};

const statusGuess = [
  {
    ...notGuess,
  },
  {
    status: 2,
    name: "Đang mở dự đoán",
    label: "Đang mở dự đoán",
    color: "#1890ff",
  },
  { status: 3, name: "Đã dự đoán", label: "Đã dự đoán", color: "#52c41a" },
];

const typeGuess = [
  { type: 1, name: "Dự đoán tỷ số", label: "Dự đoán tỷ số", color: "#f759ab" },
  {
    type: 2,
    name: "Dự đoán đội thắng",
    label: "Dự đoán đội thắng",
    color: "#722ed1",
  },
];

const typesMatch = [
  {
    type: 1,
    name: "Vòng bảng",
    value: "Vòng bảng",
    color: "#1890ff",
    label: "Vòng bảng",
  },
  {
    type: 2,
    name: "Vòng 16 đội",
    value: "Vòng 16 đội",
    color: "#36cfc9",
    label: "Vòng 16 đội",
  },
  {
    type: 3,
    name: "Vòng tứ kết",
    value: "Vòng tứ kết",
    color: "#73d13d",
    label: "Vòng tứ kết",
  },
  {
    type: 4,
    name: "Vòng bán kết",
    value: "Vòng bán kết",
    color: "#a0d911",
    label: "Vòng bán kết",
  },
  {
    type: 5,
    name: "Vòng chung kết",
    value: "Vòng chung kết",
    color: "#faad14",
  },
];

const typeScore = {
  TYPE_OFFICIAL: 1,
  TYPE_EXTRA: 2,
  TYPE_PENALTY: 3,
};

const ranking = [
  {
    id: 1,
    name: "Đồng",
    en_name: "Bronze",
    point: 0,
    color: "#1890ff",
  },
  {
    id: 2,
    name: "Bạc",
    en_name: "Silver",
    point: 4000,
    color: "#13c2c2",
  },
  {
    id: 3,
    name: "Vàng",
    en_name: "Gold",
    point: 6000,
    color: "#faad14",
  },
  {
    id: 4,
    name: "Bạch kim",
    en_name: "Platinum",
    point: 10000,
    color: "#fa541c",
  },
];

const typeGift = [
  { type: 1, name: "Điểm", color: "#1890ff" },
  { type: 2, name: "Tiền", color: "#faad14" },
  { type: 3, name: "Hiện vật", color: "#fadb14" },
];

const typePrize = [
  { type: 1, name: "Giải nhất", color: "#faad14" },
  { type: 2, name: "Giải nhì", color: "#ff9c6e" },
];

const status_noti = [
  { status: 1, name: "Chờ duyệt", color: "#faad14" },
  { status: 2, name: "Đã lên lịch", color: "##ff85c0" },
  { status: 3, name: "Đã gửi xong", color: "#597ef7" },
  { status: 4, name: "Đã xóa", color: "#08979c" },
];

const type_noti = [
  {
    type: "EURO21_01",
    name: "Tin nhắn nhắc dự đoán đầu ngày",
  },
  {
    type: "EURO21_02",
    name: "Tin nhắn nhắc dự đoán cuối ngày",
  },
  { type: "EURO21_03", name: "Danh sách người thắng giải" },
  { type: "EURO21_04", name: "Tin nhắn thắng giải" },
  {
    type: "EURO21_05",
    name: "Tin nhắn giới thiệu, quảng bá chương trình",
  },
  { type: "EURO21_06", name: "Tin nhắn Lucky Draw" },
  {
    type: "EURO21_07",
    name: "Tin nhắn thắng giải Lucky Draw",
  },
];

const noti_object = [
  { id: 1, name: "Công cộng", color: "#389e0d" },
  { id: 2, name: "Theo địa phương", color: "#faad14" },
  { id: 3, name: "Riêng tư", color: "#ffadd2" },
  { id: 4, name: "Tự động", color: "#d3adf7" },
];

const category_noti = [
  { id: 1, name: "Khuyến mãi", color: "#faad14" },
  { id: 2, name: "Sinh nhật", color: "#d4b106" },
  { id: 3, name: "Thông báo thường", color: "#a0d911" },
];

export {
  noti_object,
  category_noti,
  type_noti,
  status_noti,
  canceled,
  typePrize,
  notGuess,
  typeGift,
  confirmed,
  breadcrumb,
  statusMatch,
  typesMatch,
  typeScore,
  ranking,
  statusGuess,
  typeGuess,
  updatedScore,
};
