import { Suspense, lazy } from "react";
import { ConfigProvider } from "antd";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import viVN from "antd/lib/locale/vi_VN";
import "moment/locale/vi";

import { Loader } from "components";
import Private from "routes/Private";
import "./App.less";

const Login = lazy(() => import("features/Login"));
const Main = lazy(() => import("Main"));

function App() {
  return (
    <ConfigProvider locale={viVN} componentSize="small">
      <Suspense fallback={<Loader />}>
        <Router>
          <Switch>
            <Route exact path="/login" component={Login} />
            <Private path="/" component={Main} />
          </Switch>
        </Router>
      </Suspense>
    </ConfigProvider>
  );
}

export default App;
