import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchPlayer } from "./playerAPI";

export const loadPlayer = createAsyncThunk(
  "playerSlice/fetchPlayer",
  async (params, thunkAPI) => {
    const res = await fetchPlayer(params);
    const { total, data } = res;
    return { total, data };
  }
);

const playerSlice = createSlice({
  name: "playerSlice",
  initialState: {
    player: [],
    total: 0,
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadPlayer.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(loadPlayer.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(loadPlayer.fulfilled, (state, action) => {
      state.loading = false;
      state.player = action.payload.data;
      state.total = action.payload.total;
    });
  },
});

export const selectPlayer = (state) => state.player;

export default playerSlice.reducer;
