import { Row, Col } from "antd";

import fallbackImage from "assets/fallbackImage.png";

export default function Match({ info }) {
  return (
    <Row
      justify="space-between"
      style={{ width: "100%", padding: 0, margin: 0 }}
      align="middle"
    >
      <Col span={11}>
        <span style={{ marginRight: "6px" }}>{info.home_team_info?.name}</span>
        <img
          src={
            info.home_team_info?.images?.image
              ? `${process.env.REACT_APP_MEDIA_FILE}/${info.home_team_info?.images?.image}`
              : fallbackImage
          }
          alt="flag"
          width={30}
        />
      </Col>
      <Col span={2}>-</Col>
      <Col span={11}>
        <img
          src={
            info.away_team_info?.images?.image
              ? `${process.env.REACT_APP_MEDIA_FILE}/${info.away_team_info?.images?.image}`
              : fallbackImage
          }
          alt="flag"
          width={30}
        />{" "}
        <span style={{ marginLeft: "6px" }}>{info?.away_team_info?.name}</span>
      </Col>
    </Row>
  );
}
