import { Form, Select, InputNumber, Modal } from "antd";
import numeral from "numeral";

const { Option } = Select;

export default function UpdateGift({
  prize,
  gift,
  giftInit,
  visible,
  onCancelUpdateGift,
  onUpdateGift,
  initValueGift,
}) {
  const [form] = Form.useForm();

  const renderOption = (list, type) => {
    if (list.length > 0) {
      if (type === "PRIZE") {
        let arr = [];
        if (giftInit.length > 0) {
          arr = giftInit.reduce((result, cur) => {
            result.push(cur.prize_id);
            return result;
          }, []);
        }
        return list.map((value) => (
          <Option
            key={value.id}
            value={value.id}
            disabled={arr.includes(value.id)}
          >
            {value.name}
          </Option>
        ));
      }
      return list.map((value) => (
        <Option key={value.id} value={value.id} label={value.name}>
          {value.name} {value.value && numeral(value.value).format()}
        </Option>
      ));
    }
  };

  return (
    <Modal
      visible={visible}
      title="Cập nhật quà"
      onCancel={onCancelUpdateGift}
      okText="Xác nhận"
      onOk={() => form.submit()}
    >
      <Form
        size="middle"
        layout="vertical"
        form={form}
        onFinish={onUpdateGift}
        initialValues={initValueGift}
      >
        <Form.Item
          label="Giải"
          name="prize_id"
          rules={[{ required: true, message: "Mời chọn giải" }]}
        >
          <Select placeholder="Chọn giải">
            {renderOption(prize, "PRIZE")}
          </Select>
        </Form.Item>
        <Form.Item
          label="Quà"
          name="gift_id"
          rules={[{ required: true, message: "Mời chọn quà" }]}
        >
          <Select optionLabelProp="label" placeholder="Chọn quà">
            {renderOption(gift, "GIFT")}
          </Select>
        </Form.Item>
        <Form.Item
          label="Tổng số giải"
          name="total"
          rules={[{ required: true, message: "Mời nhập số giải" }]}
        >
          <InputNumber placeholder="Số lượng" />
        </Form.Item>
      </Form>
    </Modal>
  );
}
